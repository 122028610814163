<template>
	<router-view></router-view>
</template>

<script>

export default {
	name: 'App',
	components: {

	}
}
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
	background-color: #1f1f35;
	width: 100%;
	height: 100%;
	position: fixed;
	background-image: url('./images/matrix_bg.png');
	overflow-y: scroll;
	overflow-x: hidden;
}
body{
	margin: 0;
}

.disable-select {
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
}

.nb-confirm-btn{
	background-color: #ff8900;
	border: 1px solid #ff8900;
	color: #1f1f35;
	font-size: 1em;
	padding: 0.2em 1em;
	border-radius: 0.4em;
	margin: 0.4em;
	cursor: pointer;
	margin-right: 2em;
}

.nb-cancel-btn{
	background-color: #000000;
	border: 1px solid #ff8900;
	color: #ff8900;
	font-size: 1em;
	padding: 0.2em 1em;
	border-radius: 0.4em;
	margin: 0.4em;
	cursor: pointer;
}

.nb-confirm-dialog-text{
	color: #ff8900;
	padding: 0.4em 0;
}

.nb-confirm-btn-container{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
}

.nb-confirm-btn-row{
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 100%;
}
</style>
