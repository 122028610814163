<template>
	<div class="modal-container-bg">
		<div class="modal-container">
			<div class="modal-header-container">
				<span style="margin: 0.6em; font-size: 2em;">{{ modalTitle }}</span>
				<img v-if="showClose === true" style="width: 1em; font-size: 2em;margin: 0.6em;cursor: pointer;" src="../images/close.svg" alt="close" @click="onClose">
			</div>
			<div class="modal-content-container">
				<slot/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "ModalContainer",
	props: {
		modalTitle: String, // 声明子组件的 props，与父组件传递的属性相匹配
		showClose: {
			type: Boolean,
			default: true,
		}
	},
	methods: {
		onClose() {
			this.$emit('close');
		}
	}
}
</script>

<style scoped>
@media only screen and (max-width: 900px) {
	.modal-container {
		width: 90% !important;
	}

}
.modal-container-bg {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 999999999;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal-container {
	max-height: 80%;
	background-color: #000000;
	border-radius: 0.4em;
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 8px 8px rgba(255, 137, 0, 0.15);
}
.modal-header-container {
	width: 100%;
	background-color: transparent;
	font-size: 1em;
	text-align: start;
	display: flex;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
	color: #ff8900;
}
.modal-content-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: start;
	flex: 1;
	overflow: hidden;
	padding: 0.6em;
	font-size: 2em;
	box-sizing: border-box;
}

</style>